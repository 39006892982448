import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  *, *::after, *::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  html,
  .app-wrapper {
    overflow-x: hidden;
    position:relative;
  }

  body {
    background: #fff;
    color: #181818;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    overflow-x: hidden;
    position:relative;

    &.sticky-header {
      padding-top: 119.59px;
      @media(min-width: 50rem) {
        padding-top: 136px;
      }
    }
  }

  .app-wrapper {
    overflow-x: hidden;
  }

  h1, .h1 {
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.2;

    @media (min-width: 64rem) {
      font-size: 4rem;
    }
  }

  h2, .h2 {
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.2;

    @media (min-width: 64rem) {
      font-size: 3rem;
    }
  }

  h3, .h3 {
    font-size: 1.5rem;
  }

  h4, .h4 {
    font-size: 1rem;
    font-weight: 700;
  }

  h5, .h5 {
    font-size: .8rem;
  }

  h6, .h6 {
    font-size: .5rem;
  }

  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
  }

  a {
    color: #181818;
    text-decoration: none;
    transition: all .2s;

    &:hover {
      color: #3182CE;
    }

    &.link {
      color: #3182CE;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  button {
    font-size: 1rem;
    transition: all .2s;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  ul, ol {
    padding-left: 1.5rem;

    li {
      line-height: 1.8;
    }
  }

  .Modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, .4);
    z-index: 10;
  }

  .Backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, .4);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .css-rsvirr, [data-css-rsvirr] {
    transform: translateY(0px) !important;
  }

  .slick-next:before, .slick-prev:before {
    color: #5e5e5e;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .slick-prev {
    left: -8px;
  }

  .slick-next {
    right: -8px;
  }

  @media (max-width: 30rem) {
    .-mobile-block {
      display: block;
    }
  }
`;

export const Notice = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding: 1rem;
  background: #fdde02;

  > div {
    max-width: 1280px;
    margin: 0 auto;
  }
`;
