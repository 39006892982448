import React, { Component } from "react";
import styled from "styled-components";
import queryString from "query-string";
import ReactGA from "react-ga";
import { getRef } from "../helpers/utils";

const Form = styled.form`
  @media (min-width: 64rem) {
    display: flex;
  }
`;

const ActionsContain = styled.div`
  text-align: center;
  display: inline-block;

  @media (max-width: 64rem) {
    display: block;
  }
`;

const Button = styled.button`
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 0.8rem;
  background: #ff0283;
  color: #fff;
  border-radius: 0.2rem;
  transition: all 0.2s;
  letter-spacing: 0.1rem;
  display: block;
  border: 0;
  outline: none;
  cursor: pointer;
  height: 4rem;

  &:hover {
    background: #dc0074;
    color: #fff;
  }

  @media (min-width: 64rem) {
    border-radius: 0 0.2rem 0.2rem 0;
  }

  @media (max-width: 64rem) {
    width: 100%;
    margin-top: 1rem;
  }
`;

const ActionsContainDescription = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #ff0283;
  margin-top: 0.5rem;
`;

const Label = styled.label`
  display: block;
  min-width: 15rem;

  @media (max-width: 64rem) {
    width: 100%;
  }
`;

const Input = styled.input`
  width: 100%;
  margin: 0;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  height: 4em;
  border: 0.1rem solid #ff0283;
  border-radius: 0.2rem;
  outline: 0;

  &:focus {
    background: #faf5da;
  }

  @media (min-width: 64rem) {
    border-radius: 0.2rem 0 0 0.2rem;
  }
`;

export default class Register extends Component {
  state = {
    registerUrl:
      "https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0",
    email: "",
    disable: false,
  };

  video;

  componentDidMount() {
    const ref =
      getRef(this.props.location) ||
      queryString.parse(this.props.location.search).ref;

    if (ref) {
      this.setState({
        registerUrl: `https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0&ref=${ref}`,
      });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ disable: true });
    const { registerUrl, email } = this.state;
    const data = new FormData(event.target);

    fetch("https://quickpage.activehosted.com/proc.php", {
      method: "POST",
      body: data,
      mode: "no-cors",
    })
      .then((response) => {
        if (registerUrl.includes("ref")) {
          window.location.href = `${registerUrl}&fse=${email}`;
        } else {
          window.location.href = `https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0&fse=${email}`;
        }

        this.setState({ disable: false });
        ReactGA.event({
          category: "Register",
          action: "Register form",
          label: this.props.gaTrackLabel,
        });
      })
      .catch((error) => {
        this.setState({ disable: false });
        console.log("Request failed", error);
      });
  };

  render() {
    const { email, disable } = this.state;
    return (
      <Form
        onSubmit={this.onSubmit}
        action="https://quickpage.activehosted.com/proc.php"
      >
        <input type="hidden" name="u" value="27" />
        <input type="hidden" name="f" value="27" />
        <input type="hidden" name="s" />
        <input type="hidden" name="c" value="0" />
        <input type="hidden" name="m" value="0" />
        <input type="hidden" name="act" value="sub" />
        <input type="hidden" name="v" value="2" />
        <Label>
          <Input
            type="email"
            name="email"
            value={email}
            required={true}
            placeholder="Your email address"
            onChange={(evt) => this.setState({ email: evt.target.value })}
          />
        </Label>
        <ActionsContain>
          <Button disabled={disable} type="submit">
            Get started for FREE
          </Button>
          <ActionsContainDescription>
            5 min learning curve
          </ActionsContainDescription>
        </ActionsContain>
      </Form>
    );
  }
}
