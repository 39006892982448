import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Example from "./example";

const ModalContain = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;

  @media (min-width: 64rem) {
    .Example {
      padding: 4rem 3rem;
    }
  }
`;

const Scroll = styled.div`
  overflow-y: auto;
  max-height: 100vh;
  position: relative;
  padding: 1rem;
`;

const Button = styled.button`
  position: absolute;
  right: 2rem;
  top: 1rem;
  color: #181818;
  border: 0;
  background: none;
  font-size: 2.5rem;
  line-height: 1;
  outline: none;
  cursor: pointer;
`;

class ModalExample extends Component {
  modalRoot = document.getElementById("___gatsby");
  el = document.createElement("div");

  state = {};

  componentDidMount() {
    this.el.className = "Modal";
    this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }

  renderModalContaier() {
    return (
      <ModalContain>
        <Scroll>
          <Button onClick={this.props.closeHandler}>×</Button>
          <Example {...this.props} />
        </Scroll>
      </ModalContain>
    );
  }

  render() {
    return ReactDOM.createPortal(this.renderModalContaier(), this.el);
  }
}

export default ModalExample;
