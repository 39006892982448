import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// sub-components
import Contain from "./contain";
import Row from "./row";
import Column from "./column";

import ic from "../images/ic.png";

const Footer = styled.footer`
  padding: 0 0 5rem;
`;
const Heading = styled.h4`
  margin: 0 0 0.5rem;
`;

const Img = styled.img`
  display: block;
  margin: 0 auto;

  @media (max-width: 64rem) {
    margin-bottom: 2rem;
  }
`;

const FooterContain = styled.div`
  margin: 0 0 1rem;

  a {
    display: block;
    line-height: 1.6;
    margin: 0 0 0.5rem;
  }
`;

const FooterCopywrite = styled.div`
  font-size: 0.7rem;
  color: #787878;
  font-style: italic;
`;

export default () => {
  return (
    <Footer>
      <Contain>
        <Row>
          <Column>
            <FooterContain>
              <Heading>Quickpage apps</Heading>
              <a
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/quickpage-app/id1087963048"
                target="_blank"
              >
                iOS
              </a>
              <a
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=quickpage.app.com&hl=en"
                target="_blank"
              >
                Android
              </a>
              <a
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/quickpage/idhihbgecmlmlcdageggndgeeffhcnbf?hl=en-US"
                target="_blank"
              >
                Chrome extension
              </a>
            </FooterContain>
          </Column>
          <Column>
            <FooterContain>
              <Heading>Pricing</Heading>
              <Link to="/pricing">Pricing</Link>
            </FooterContain>
          </Column>
          <Column>
            <FooterContain>
              <Heading>Keep in touch</Heading>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.Intercom("show");
                }}
              >
                Contact
              </a>

              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.Intercom("show");
                }}
              >
                Support
              </a>
              <a href="https://status.quickpage.io">Status</a>
              <a
                rel="noopener noreferrer"
                href="https://www.facebook.com/quickpageapp"
                target="_blank"
              >
                Facebook
              </a>
              <a
                rel="noopener noreferrer"
                href="https://www.instagram.com/quickpage"
                target="_blank"
              >
                Instagram
              </a>
              <a
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/quickpage"
                target="_blank"
              >
                LinkedIn
              </a>
            </FooterContain>
          </Column>

          <Column>
            <FooterContain>
              <Heading>Fine print</Heading>
              <Link to="/terms">Terms</Link>
              <Link to="/privacy">Privacy</Link>
            </FooterContain>
          </Column>
          <Column>
            <Img src={ic} alt="Quickpage Ironclad Guarantee" />
          </Column>
        </Row>

        <FooterCopywrite>
          Copyright © {new Date().getFullYear()} Quickpage. All rights reserved.
        </FooterCopywrite>
      </Contain>
    </Footer>
  );
};
