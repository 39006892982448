import React from "react";
import styled from "styled-components";

const Row = styled.div`
  margin: 0 -1rem;

  @media (min-width: 64rem) {
    display: flex;
  }

  > * {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export default ({ children, ...rest }) => {
  return <Row className="Row">{children}</Row>;
};
