import Cookies from 'js-cookie';
import queryString from 'query-string';

export const setRef = (location) => {
  const refId = queryString.parse(location.search).ref;

  if (refId) {
    Cookies.set('refId', refId, { expires: 7 });
  }
};

export const getRef = (location) => {
  return Cookies.get('refId') || queryString.parse(location.search).ref;
};
