import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from "gatsby";
import styled from "styled-components";

export const PopupContain = styled.div`
  position: fixed;

  bottom: 1rem;
  left: 1rem;
  background: #5521fa;
  padding: 1rem;
  text-align: center;
  color: #fff;
  font-weight: 600;
  border-radius: 0.4rem;
  z-index: 1;

  @media (min-width: 40rem) {
    max-width: 30rem;
  }

  @media (max-width: 40rem) {
    right: 1rem;
  }

  a {
    color: #fff;
    text-decoration: underline;
    transition: all 0.2s;

    &:hover {
      color: #fff;
      opacity: 0.7;
    }
  }
`;

export const Button = styled.button`
  background: #ffe202;
  border: 0;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  min-width: 10rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #181818;
  margin-top: 1rem;
  cursor: pointer;
  outline: none;

  &:hover {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }
`;

class Popup extends Component {
  state = {
    ac: false,
  };

  modalRoot = document.getElementById("___gatsby");
  el = document.createElement("div");

  componentDidMount() {
    setTimeout(() => {
      window.cookieconsent.initialise({
        palette: {
          popup: {
            background: "#3937a3",
          },
          button: {
            background: "#e62576",
          },
        },
        theme: "classic",
        position: "bottom-left",
        content: {
          href: "/privacy",
          message:
            "This website uses cookies to ensure you get the best experience.",
        },
      });
    }, 15000);

    // this.el.className = "Popup";
    // this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    // this.modalRoot.removeChild(this.el);
  }

  closeHandler = () => {
    this.setState({ ac: true }, () => {
      localStorage.setItem("qp.ac", true);
    });
  };

  getLocalShorage() {
    return localStorage.getItem("qp.ac") || this.state.ac;
  }

  renderContainer() {
    return (
      <PopupContain>
        <div>
          This website uses cookies to ensure you get the best experience.{" "}
          <Link to="/privacy">Learn More</Link>
        </div>
        <Button onClick={this.closeHandler}>Got it!</Button>
      </PopupContain>
    );
  }

  render() {
    return null;
    return this.getLocalShorage()
      ? null
      : ReactDOM.createPortal(this.renderContainer(), this.el);
  }
}

export default Popup;
