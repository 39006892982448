import React from "react";
import styled from "styled-components";

const Contain = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
`;

export default ({ children, ...rest }) => {
  return <Contain {...rest}>{children}</Contain>;
};
