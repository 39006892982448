import React from "react";

export default ({ children, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
      <path
        fill="#46A85C"
        d="M36.667 0H3.333A3.333 3.333 0 000 3.333v33.334A3.333 3.333 0 003.333 40h33.334A3.333 3.333 0 0040 36.667V3.333A3.333 3.333 0 0036.667 0z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M25.845 27.38a9.53 9.53 0 002.052-2.067 9.253 9.253 0 001.323-2.606c.31-.95.464-1.954.469-3.023a9.826 9.826 0 00-.505-3.133 9.357 9.357 0 00-1.424-2.668 9.406 9.406 0 00-2.196-2.069 10.226 10.226 0 00-2.825-1.337 11.527 11.527 0 00-3.31-.477c-1.174.005-2.274.16-3.311.473a10.19 10.19 0 00-2.825 1.332 9.588 9.588 0 00-2.197 2.064 9.376 9.376 0 00-1.423 2.669 9.88 9.88 0 00-.506 3.146c.008 1.368.26 2.637.76 3.805a9.339 9.339 0 002.097 3.06 9.961 9.961 0 003.165 2.06c2.161.893 5.06.95 7.56.238-1.508-1.245-2.733-2.26-2.988-2.46a14.437 14.437 0 01-.38-.298l-.005-.003a14.7 14.7 0 01-.544-.466 5.667 5.667 0 01-1.62-.426 5.956 5.956 0 01-3.15-3.16 5.986 5.986 0 01-.474-2.35c-.002-.804.157-1.6.469-2.341a5.822 5.822 0 011.272-1.878 5.845 5.845 0 014.153-1.7 5.787 5.787 0 012.252.45c.695.3 1.31.713 1.837 1.25a5.743 5.743 0 011.242 1.878c.3.718.45 1.495.455 2.341a6.145 6.145 0 01-.455 2.35 5.86 5.86 0 01-2.598 2.927c.35.44 1.345 1.677 2.501 3.119a8.197 8.197 0 001.13-.7z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M18.205 18.7c2.888-.177 5.472 2.958 7.128 4.95 1.194 1.437 7.57 9.94 7.4 10.194-.224.33-5.488-3.52-8.44-6.037a49.374 49.374 0 01-2.071-1.863c-1.906-1.818-4.456-4.11-4.017-7.244z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
