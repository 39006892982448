import React, { Component } from "react";
import PhoneInput from "react-phone-number-input";
import { Link } from "gatsby";
import styled, { keyframes } from "styled-components";
import { toast } from "react-toastify";
import ReactGA from "react-ga";

// sub-components
import Contain from "./contain";
import ModalExample from "./modal-example";
import examplePattern from "../images/example-pattern.png";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ExampleSection = styled.section`
  padding: 5rem 0 5rem;
  background-color: #f5f1eb;
  background-image: url(${examplePattern});

  .PhoneInput {
    background: #fff;
    border: 2px solid #46a85c;
    padding: 0 0.5rem 0 1rem;
    height: 4rem;
    font-size: 1rem;
    min-width: 12rem;
    border-radius: 0.4rem;
    color: #181818;
    outline: none;
    overflow: hidden;

    @media (min-width: 64rem) {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    @media (max-width: 64rem) {
      width: 100%;
      margin: 0 0 1rem;
    }

    input {
      padding: 0.5rem;
      outline: none;
      border: 0;
      font-size: 1rem;
      color: #181818;
      height: 4rem;
    }
  }

  &.-red {
    .PhoneInput,
    input,
    button {
      border-color: #e53e3e;
    }

    button {
      background-color: #e53e3e;
    }
  }
`;

const Heading = styled.h2`
  text-align: center;
  margin: 0 0 1.5rem;
`;

const Excerpt = styled.p`
  text-align: center;
  max-width: 48rem;
  margin: 0 auto 1.5rem;

  @media (min-width: 64rem) {
    font-size: 1.5rem;
    line-height: 1.6;
  }
`;

const Notice = styled.p`
  text-align: center;
  color: #5e5e5e;
  font-size: 0.8rem;

  a {
    text-decoration: underline;
  }
`;

const Form = styled.form`
  max-width: 44rem;
  margin: 0 auto 1.5rem;

  @media (min-width: 64rem) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const FormArea = styled.div`
  width: 100%;

  @media (min-width: 64rem) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .-hidden {
    display: none;
  }
`;

const Input = styled.input`
  background: #fff;
  border: 2px solid #46a85c;
  padding: 0.5rem 1rem;
  height: 4rem;
  font-size: 1rem;
  min-width: 12rem;
  border-radius: 0.4rem;
  color: #181818;
  outline: none;

  &:focus {
    background: #faf5da;
  }

  @media (min-width: 64rem) {
    &:first-child {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    &:nth-child(2),
    &.-name {
      border-left: 0;
      border-radius: 0;
    }
  }

  @media (max-width: 64rem) {
    width: 100%;
    margin: 0 0 1rem;
  }
`;

const SelectWrapper = styled.div`
  min-width: 12rem;
  position: relative;

  &:after {
    content: "▼";
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    padding: 0 1rem;
    color: #e5e5e5;
    pointer-events: none;
  }
`;

const Select = styled.select`
  background: #fff;
  border: 2px solid #46a85c;
  appearance: none;
  padding: 0.5rem 1rem;
  height: 4rem;
  font-size: 1rem;
  width: 100%;
  border-radius: 0.4rem;
  color: #181818;
  outline: none;

  &:focus {
    background: #faf5da;
  }

  option:not(:first-of-type) {
    color: #787878;
  }

  @media (min-width: 64rem) {
    border-left: 0;
    border-radius: 0;
  }

  @media (max-width: 64rem) {
    width: 100%;
    margin: 0 0 1rem;
  }
`;

const Button = styled.button`
  background: #46a85c;
  border: 2px solid #46a85c;
  padding: 0.5rem 1rem;
  height: 4rem;
  font-size: 1rem;
  border-radius: 0.4rem;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.1rem;
  cursor: pointer;
  transition: all 0.2;
  outline: none;
  white-space: nowrap;
  text-align: center;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }

  @media (min-width: 64rem) {
    border-radius: 0 0.4rem 0.4rem 0;
  }

  @media (max-width: 64rem) {
    width: 100%;
  }
`;

const scaleAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const drawCircle = keyframes`
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const drawCheck = keyframes`
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const IconContain = styled.div`
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  #successAnimationCircle {
    stroke-dasharray: 151px 151px;
    stroke: #45a85c;
  }

  #successAnimationCheck {
    stroke-dasharray: 36px 36px;
    stroke: #45a85c;
  }

  #successAnimationResult {
    fill: #45a85c;
    opacity: 0;
  }

  #successAnimation.animated {
    animation: 1s ease-out 0s 1 both ${scaleAnimation};

    #successAnimationCircle {
      animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both ${drawCircle},
        0.3s linear 0.9s 1 both ${fadeOut};
    }

    #successAnimationCheck {
      animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both ${drawCheck},
        0.3s linear 0.9s 1 both ${fadeOut};
    }

    #successAnimationResult {
      animation: 0.3s linear 0.9s both ${fadeIn};
    }
  }
`;

const industryOptions = [
  {
    value: "real-estate",
    label: "Real Estate",
  },
];

function Icon() {
  return (
    <svg
      id="successAnimation"
      className="animated"
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
    >
      <path
        id="successAnimationResult"
        fill="#45a85c"
        d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
      />
      <circle
        id="successAnimationCircle"
        cx="35"
        cy="35"
        r="24"
        stroke="#45a85c"
        strokeWidth="2"
        strokeLinecap="round"
        fill="transparent"
      />
      <polyline
        id="successAnimationCheck"
        stroke="#45a85c"
        strokeWidth="2"
        points="23 34 34 43 47 27"
        fill="transparent"
      />
    </svg>
  );
}

class Example extends Component {
  state = {
    step: this.props.step || 1,
    Name: this.props.Name || "",
    Email: this.props.Email || "",
    Phone: this.props.Phone || "",
    Industry: this.props.Industry || "real-estate",
    heading:
      this.props.heading || "Send a sample Quickpage to yourself right now",
    excerpt:
      this.props.step === 1 || !this.props.step
        ? `We can talk to you all day about how cool Quickpage is OR we can <strong>SHOW YOU RIGHT NOW</strong>! <span class="-mobile-block">(You really need to see this to believe it.)</span>`
        : `Stats show that 90% of texts get opened up within 3 minutes. <br />You gotta see this...`,
    inline: this.props.inline || false,
    visible: false,
    phone: null,
  };

  changeHandler = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onChangePhone = (value) => {
    setTimeout(() => {
      this.setState({ Phone: value });
    });
  };

  closeHandler = () => {
    const updatedState = {
      visible: false,
      step: 1,
      Email: "",
      Industry: "real-estate",
      Phone: "",
      Name: "",
      heading: "Send a sample Quickpage to yourself right now",
      excerpt: `We can talk to you all day about how cool Quickpage is OR we can <strong>SHOW YOU RIGHT NOW</strong>! <span class="-mobile-block">(You really need to see this to believe it.)</span>`,
    };

    this.setState(updatedState);
  };

  submitHandler = (e) => {
    e.preventDefault();
    const form = e.target;
    const { Name, Industry, Email, Phone, step, inline } = this.state;

    if (step === 1 && (!Email || !Industry)) {
      return toast.error("Please fill all the fields.");
    }

    if (step === 2 && (!Name || !Phone)) {
      return toast.error("Please fill all the fields.");
    }

    const body = { Name, Email, Phone, Industry };
    let notice = "Quickpage Delivered. Check your inbox.";
    let heading = "Would you like to receive it by text too?";

    if (step === 2) {
      notice = "Quickpage Delivered. Check your phone.";
      heading = `Thank you, ${Name}!`;
    }

    toast.success(notice);

    fetch("/", {
      method: "POST",
      mode: "no-cors",
      headers: {
        Accept: "application/x-www-form-urlencoded;charset=UTF-8",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: encode({ "form-name": form.getAttribute("name"), ...body }),
    })
      .then((r) => {
        this.setState(
          {
            step: step + 1,
            notice,
            heading,
            excerpt:
              step === 1
                ? `Stats show that 90% of texts get opened up within 3 minutes. <br />You gotta see this...`
                : `Your example Quickpage will be delivered to your email and phone.`,
            visible: inline,
          },
          () => {
            localStorage.setItem("qp.pr", true);
            ReactGA.event({
              category: "Page Request",
              action: step === 2 ? "Via SMS" : "Via Email",
              label: this.props.gaTrackLabel || "Page Request",
            });
          }
        );
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong. Please try again.");
      });
  };

  render() {
    const {
      step,
      Name,
      Industry,
      Email,
      Phone,
      heading,
      excerpt,
      visible,
    } = this.state;

    return (
      <ExampleSection className={`Example ${step === 2 ? "-red" : ""}`}>
        <Contain>
          <Heading>{heading}</Heading>
          <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />

          <Form
            name={`Page Request`}
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.submitHandler}
          >
            {step !== 3 ? (
              <FormArea>
                <Input
                  type="email"
                  name="Email"
                  value={Email}
                  onChange={this.changeHandler}
                  placeholder="Your email address"
                  className={`${step === 2 && "-hidden"}`}
                />

                <SelectWrapper className={`${step === 2 && "-hidden"}`}>
                  <Select
                    name="Industry"
                    defaultValue={Industry}
                    onChange={this.changeHandler}
                  >
                    {industryOptions.map((i) => (
                      <option key={i.value} value={i.value}>
                        {i.label}
                      </option>
                    ))}
                  </Select>
                </SelectWrapper>

                <PhoneInput
                  placeholder="Your cell number"
                  value={Phone}
                  type="text"
                  defaultCountry="US"
                  countries={["US", "AU", "CA"]}
                  onChange={this.onChangePhone}
                  className={`${step === 1 && "-hidden"}`}
                />
                <input type="hidden" name="Phone" value={Phone} />

                <Input
                  type="text"
                  name="Name"
                  value={Name}
                  onChange={this.changeHandler}
                  placeholder="Name"
                  className={`${step === 1 && "-hidden"} -name`}
                />

                <Button type="submit">
                  {step === 1 ? "Send me a Quickpage" : "Get a Text"}
                </Button>
              </FormArea>
            ) : (
              <IconContain>
                <Icon />
              </IconContain>
            )}
          </Form>

          {visible && (
            <ModalExample
              step={step}
              Email={Email}
              Industry={Industry}
              heading={heading}
              closeHandler={this.closeHandler}
            />
          )}

          <Notice>
            By clicking “<strong>Send me a Quickpage</strong>” I agree to
            Quickpage <Link to="/terms">Terms of Service</Link> and{" "}
            <Link to="/privacy">Privacy Policy</Link>
          </Notice>
        </Contain>
      </ExampleSection>
    );
  }
}

export default Example;
