import React, { Component } from "react";
import { Link } from "gatsby";
import queryString from "query-string";
import styled from "styled-components";
import ReactGA from "react-ga";
import { getRef } from "../helpers/utils";

// components
import Contain from "./contain";
import Logo from "./logo";
import LogoIcon from "./logo-icon";
import ModalExample from "./modal-example";

// styles
const HeaderSection = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  transition: all 0.2s;

  &.bg-auto,
  &.bg-re {
    @media (min-width: 72rem) {
      .top-nav__link {
        color: #fff;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .button-mobile-menu {
      background-color: #fff;
      &:before,
      &:after {
        background-color: #fff;
      }
    }
  }

  .on-sticky-show {
    display: none !important;
  }

  ${(props) =>
    props.sticky &&
    `
    background-color: rgba(255, 255, 255, 0.95);

    .HeaderContain {
      padding: 1rem 0;
    }

    .top-nav__link {
      color: #181818 !important;

      &:hover {
        color: #3182CE !important;
      }
    }

    .button-mobile-menu {
      background-color: #181818 !important;
      &.-open {
        background-color: transparent !important;
      }
      &:before,
      &:after {
        background-color: #181818 !important;
      }
    }

    @media (max-width: 72rem) {
      .on-sticky-hide {
        display: none !important;
      }

      .on-sticky-show {
        display: block !important;
      }
    }
  `}
`;
const HeaderDesktop = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;

  .top-nav__logo {
    display: block;
    height: 40px;
  }
`;

const HideMobile = styled.span`
  display: none;
  @media (min-width: 72rem) {
    display: block;
  }
`;

const HideDesktop = styled.span`
  @media (min-width: 72rem) {
    display: none;
  }
`;

const HeaderNav = styled.nav`
  .top-nav__link {
    display: inline-block;
    margin: 0 0.5rem;
    font-weight: 600;

    &.active {
      border-bottom: 1px dashed #3182ce;
    }
  }

  @media (max-width: 72rem) {
    display: none;

    ${(props) =>
      props.isMenuOpen &&
      `
      display: block;
      position: fixed;
      top: 109px;
      width: 100%;
      left: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.95);
      padding: 0 2rem 2rem;

      .top-nav__link {
        display: block;
        padding: .5rem 0;
        margin: 0 0 .5rem;
      }
    `}

    ${(props) =>
      props.sticky &&
      `
      top: 74px;
    `}
  }
`;

const ExtendButtonMenuStyle = `
  content: "";
  position: absolute;
  left: 0;
  width: 32px;
  height: 5px;
  border-radius: 15px;
  background-color: #181818;
  border: 0;
  outline: none;
  transition: all .25s;
`;

const Mobile = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  @media (max-width: 72rem) {
    display: flex;
  }
`;

const Button = styled.a`
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0.2rem;
  transition: all 0.2s;
  margin-left: 1rem;
  letter-spacing: 0.1rem;
  text-align: center;
  padding: 0.5rem 1rem;
  background: #ff0283;
  color: #fff;
  border: 2px solid #ff0283;

  &:hover {
    background: #dc0074;
    color: #fff;
    border-color: #dc0074;
  }

  @media (max-width: 72rem) {
    margin: 0;
    width: 100%;
    display: block;
  }
`;

const ButtonMobile = styled.a`
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0.2rem;
  transition: all 0.2s;
  margin-left: 1rem;
  letter-spacing: 0.1rem;
  text-align: center;
  padding: 0.5rem;
  background: #ff0283;
  color: #fff;
  border: 2px solid #ff0283;

  &:hover {
    background: #dc0074;
    color: #fff;
    border-color: #dc0074;
  }
`;

const ButtonExample = styled.a`
  flex-shrink: 0;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  transition: all 0.2s;
  margin-left: 1rem;
  text-align: center;
  background: none;
  color: #45a85c;
  border: 2px solid #45a85c;

  &:hover {
    background: #37864a;
    border-color: #37864a;
    color: #fff;
  }

  @media (max-width: 72rem) {
    margin: 0 0 1rem;
    width: 100%;
    display: block;
  }
`;

const ButtonExampleMobile = styled.a`
  flex-shrink: 0;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 0.2rem;
  transition: all 0.2s;
  margin-left: 1rem;
  text-align: center;
  background: none;
  color: #45a85c;
  border: 2px solid #45a85c;

  &:hover {
    background: #37864a;
    border-color: #37864a;
    color: #fff;
  }
`;

const ButtonMenu = styled.button`
  ${ExtendButtonMenuStyle}
  position: relative;
  z-index: 10;
  margin-left: 0.5rem;
  @media (min-width: 72rem) {
    display: none;
  }
  &:before {
    ${ExtendButtonMenuStyle}
    top: -8px;
  }
  &:after {
    ${ExtendButtonMenuStyle}
    top: 8px;
  }
  ${(props) =>
    props.isMenuOpen &&
    `
    background-color: transparent !important;
    transition: all .25s;
    &:before {
      transition: all .25s;
      top: -0px;
      transform: rotate(45deg);
    }
    &:after {
      transition: all .25s;
      top: -0px;
      transform: rotate(-45deg);
    }
  `}
`;

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

class Header extends Component {
  state = {
    sticky: false,
    isMenuOpen: false,
    registerUrl:
      "https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0",
    visible: false,
    industry: "",
  };

  scrollHandler = () => {
    let sticky = false;
    if (window.pageYOffset > 80) {
      sticky = true;
    } else {
      sticky = false;
    }

    this.setState({ sticky });
  };

  componentDidMount() {
    const { location } = this.props;
    window.addEventListener("scroll", debounce(this.scrollHandler, 16));
    const ref = getRef(location) || queryString.parse(location.search).ref;

    if (ref) {
      this.setState({
        registerUrl: `https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0&ref=${ref}`,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", debounce(this.scrollHandler, 16));
  }

  linkClickHandler = (evt) => {
    evt.preventDefault();
    this.setState({ visible: !this.state.visible }, () => {
      ReactGA.event({
        category: "Example button",
        action: "click",
        label: "Example",
      });
    });
  };

  signInLogger = () => {
    ReactGA.event({
      category: "Sign In",
      action: "click",
      label: "Sign In",
    });
  };

  registerLogger = () => {
    ReactGA.event({
      category: "Register",
      action: "click",
      label: "Free Trial",
    });
  };

  trialMobileLogger = () => {
    ReactGA.event({
      category: "Register",
      action: "click",
      label: "Mobile Free Trial",
    });
  };

  render() {
    const { registerUrl, isMenuOpen, sticky, visible, industry } = this.state;
    const { cssClass } = this.props;

    return (
      <HeaderSection className={cssClass} sticky={sticky}>
        <Contain>
          <HeaderDesktop className="HeaderContain">
            <Link className="top-nav__logo on-sticky-hide" to="/">
              <Logo />
            </Link>

            <Link className="top-nav__logo on-sticky-show" to="/">
              <Logo />
            </Link>

            <Mobile>
              <ButtonExampleMobile
                className="on-sticky-show"
                href="/#example"
                onClick={this.linkClickHandler}
              >
                Example
              </ButtonExampleMobile>
              <ButtonMobile
                className="on-sticky-show"
                href={registerUrl}
                onClick={this.trialMobileLogger}
              >
                Trial
              </ButtonMobile>
              <ButtonMenu
                className={`button-mobile-menu ${isMenuOpen && "-open"}`}
                onClick={() => this.setState({ isMenuOpen: !isMenuOpen })}
                isMenuOpen={isMenuOpen}
              ></ButtonMenu>
            </Mobile>

            <HeaderNav isMenuOpen={isMenuOpen} sticky={sticky}>
              <Link className="top-nav__link" to="/" activeClassName="active">
                Real Estate
              </Link>
              <Link
                className="top-nav__link"
                to="/testimonials"
                activeClassName="active"
              >
                Testimonials
              </Link>
              <Link
                className="top-nav__link"
                to="/pricing"
                activeClassName="active"
              >
                Pricing
              </Link>
              <Link
                className="top-nav__link"
                to="/faq"
                activeClassName="active"
              >
                FAQ
              </Link>
              <a
                className="top-nav__link"
                href="https://quickpageapp.com/sign-in"
                onClick={this.signInLogger}
              >
                Sign In
              </a>
              <ButtonExample href="/#example" onClick={this.linkClickHandler}>
                Example
              </ButtonExample>
              <Button href={registerUrl} onClick={this.registerLogger}>
                Free Trial
              </Button>
            </HeaderNav>

            {visible && (
              <ModalExample
                visible={visible}
                Industry={industry}
                closeHandler={this.linkClickHandler}
                gaTrackLabel="Header Menu"
              />
            )}
          </HeaderDesktop>
        </Contain>
      </HeaderSection>
    );
  }
}

export default Header;
