import React, { Component } from "react";
import ModalExample from "./modal-example";

class ModalExit extends Component {
  state = {
    industry: "",
    show: false,
  };

  componentDidMount() {
    document.addEventListener("mouseout", (event) => {
      if (
        event.clientY < 50 &&
        event.relatedTarget == null &&
        event.target.nodeName.toLowerCase() !== "select"
      ) {
        if (!this.getLocalShorage()) {
          this.show();
        }
      }
    });

    ["beforeunload", "blur", "pagehide"].forEach((event) => {
      window.addEventListener(event, () => {
        if (!this.getLocalShorage()) {
          this.show();
        }
      });
    });
  }

  show = () => {
    const { location } = this.props;
    if (!location) {
      return;
    }

    let industry = "";
    if (location.pathname === "/real-estate") {
      industry = "real-estate";
    }

    if (location.pathname === "/automotive") {
      industry = "automotive";
    }

    this.setState({ industry, show: true });
  };

  closeHandler = () => {
    this.setState({ show: false }, () => {
      sessionStorage.setItem("qp.close", true);
    });
  };

  getLocalShorage() {
    return localStorage.getItem("qp.pr") || sessionStorage.getItem("qp.close");
  }

  render() {
    return this.state.show && !this.getLocalShorage() ? (
      <ModalExample
        visible={this.state.show}
        Industry={this.state.industry}
        heading="Before you go, get an example right now!"
        closeHandler={this.closeHandler}
        gaTrackLabel="Exit Modal"
      />
    ) : null;
  }
}

export default ModalExit;
