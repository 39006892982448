import React from "react";
import styled from "styled-components";

const Column = styled.div`
  padding: 0 1rem;

  ${(props) =>
    props.half &&
    `
    @media(min-width: 64rem) {
      width: 50%;
    }
  `}

  &.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
`;

export default ({ children, classes, half, ...rest }) => {
  return (
    <Column className={`Column ${classes}`} half>
      {children}
    </Column>
  );
};
