import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import Header from "../header";
import Footer from "../footer";
import Popup from "../popup";
import ModalExit from "../modal-exit";

import { GlobalStyle, Notice } from "./styles";

export default ({ children, location, cssClass }) => {
  const hasWindow = typeof window !== `undefined`;
  return (
    <div className="app-wrapper">
      {/* <Notice>
        <div>

        </div>
      </Notice> */}

      <GlobalStyle />
      <Header cssClass={cssClass} location={location} />

      {children}
      <Footer />

      {hasWindow && (
        <div>
          <Popup />
          <ModalExit location={location} />
        </div>
      )}

      <ToastContainer />
    </div>
  );
};
